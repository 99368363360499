import { truncate } from 'lodash';
import React from 'react';
import Layout from '../components/layout';
import ProductPage from '../components/scenes/product';
import SEO from '../components/seo';

const ProductPageTemplate = ({ pageContext: product }) => (
  <Layout>
    <SEO
      title={product.name}
      description={truncate(product.description, { length: 140 })}
      imageUrl={product.remoteImage?.childImageSharp?.fixed.src}
    />
    <ProductPage product={product} />
  </Layout>
);

export default ProductPageTemplate;
