import '@actovos-consulting-group/react-lightbox-component/build/css/index.css';
import React from 'react';

const Lightbox = React.lazy(() =>
  import('@actovos-consulting-group/react-lightbox-component'),
);

const ProductScreenshots = ({ screenshots }) => {
  const isSSR = typeof window === 'undefined';

  if (isSSR) {
    return null;
  }

  const images = screenshots.map(img => ({
    src: img.childImageSharp?.fixed.src,
  }));

  return (
    <React.Suspense fallback={<div />}>
      {images && <Lightbox showImageModifiers={false} images={images} />}
    </React.Suspense>
  );
};

export default ProductScreenshots;
