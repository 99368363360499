import {
  Block,
  Button,
  Clickable,
  Container,
  Flex,
  Tag,
  Text,
  Toaster,
} from '@actovos-consulting-group/ui-core';
import { RiExternalLinkLine } from '@react-icons/all-files/ri/RiExternalLinkLine';
import { SiLinkedin } from '@react-icons/all-files/si/SiLinkedin';
import { SiTwitter } from '@react-icons/all-files/si/SiTwitter';
import firebase from 'firebase/app';
import { Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useAsyncFn } from 'react-use';
import styled, { useTheme } from 'styled-components';
import { useAuthContext } from '../../../../firebase/context';
import withHttp from '../../../../utils/with-http';
import ProductScreenshots from './product-screenshots';

const LoginRegisterModal = React.lazy(() =>
  import('../../../login-register-modal'),
);

const LinkedInButton = styled.a`
  background-color: ${p => p.theme.colors.linkedInBlue};
  padding: 10px;
  border-radius: 5px;
  color: white;
  height: 35px;
  margin-right: 5px;
`;

const TwitterButton = styled(LinkedInButton)`
  background-color: ${p => p.theme.colors.twitterBlue};
`;

const Divide = styled(Block)`
  height: 1px;
  margin: 20px 0;
  width: 100%;
  background: ${p => p.theme.colors.mutedGrey};
`;

const ProductHero = ({
  product: {
    url,
    name,
    description,
    linkedIn,
    twitter,
    User,
    industries = [],
    screenshotImages = [],
    firebaseID,
    remoteImage,
  },
}) => {
  const theme = useTheme();
  const isSSR = typeof window === 'undefined';
  const [showLoginModal, setModal] = useState(false);
  const { user: authUser } = useAuthContext();
  const mainImage = getImage(remoteImage);
  const matches = useMediaQuery({ query: '(max-width: 700px)' });
  const hasScreenshots = screenshotImages && screenshotImages.length;

  const [{ loading }, claimProduct] = useAsyncFn(async userID => {
    await firebase
      .firestore()
      .collection('ProductClaims')
      .add({
        User: firebase.firestore().doc(`/Users/${userID}`),
        Product: firebase.firestore().doc(`/Products/${firebaseID}`),
      });

    setModal(false);

    Toaster.alert({ value: 'Thanks! We will reach out!', variant: 'success' });
  });

  const productScreenshots = () => {
    return (
      !!hasScreenshots && (
        <>
          <Divide />
          <Text mt={20} mb={20} fontWeight="bold">
            Product Screenshots
          </Text>
          <ProductScreenshots screenshots={screenshotImages} />
        </>
      )
    );
  };

  const handleClaim = () => {
    if (!authUser.id) {
      setModal(true);
    } else {
      claimProduct(authUser.id);
    }
  };

  const handleNavigateProduct = () => {
    const prodURL = new URL(withHttp(url));
    prodURL.searchParams.set('ref', 'roastortoast');
    window.open(prodURL, '_blank').focus();
  };

  return (
    <Block pb={40}>
      <Container>
        <Flex flexDirection={{ _: 'column', xs: 'row' }}>
          <Block mr={{ _: 0, xs: 40 }}>
            <Block width={{ _: '40vw', xs: 200 }} m={{ _: 'auto', xs: 0 }}>
              {mainImage ? (
                <GatsbyImage
                  image={mainImage}
                  alt={name}
                  style={{ borderRadius: 4, width: '100%' }}
                  width="100%"
                />
              ) : (
                <img
                  alt={name}
                  src="/img/placeholder_square.jpg"
                  style={{ width: '100%' }}
                />
              )}
            </Block>
            <Flex mt={2}>
              {linkedIn && (
                <LinkedInButton href={linkedIn} target="_blank">
                  <SiLinkedin />
                </LinkedInButton>
              )}
              {twitter && (
                <TwitterButton href={twitter} target="_blank">
                  <SiTwitter />
                </TwitterButton>
              )}
            </Flex>
            {!User && (
              <Flex mt={4} justifyContent="center">
                <Button loading={loading} onClick={handleClaim}>
                  Claim this page
                </Button>
              </Flex>
            )}
          </Block>
          <Block mt={{ _: 20, xs: 0 }}>
            <Flex alignItems="center">
              <Text
                as="h2"
                fontSize={{ _: 30, xs: 50 }}
                fontWeight="bold"
                m={0}
                mr={10}
              >
                {name}
              </Text>
              {url && (
                <Clickable onClick={handleNavigateProduct}>
                  <RiExternalLinkLine size={25} color={theme.colors.primary} />
                </Clickable>
              )}
            </Flex>
            <Text mb={20} as="p" lineHeight="1.3rem">
              {description}
            </Text>
            {User && (
              <Text fontStyle="italic">
                Submitted By:{' '}
                <Link to={`/user/${User.username}`}>{User.name}</Link>
              </Text>
            )}
            {!!hasScreenshots && !matches && productScreenshots()}
            {!!industries.length && (
              <Block mt={1}>
                <Divide />
                {industries.map(tag => (
                  <Tag size="lg" key={tag} variant="light">
                    {tag}
                  </Tag>
                ))}
              </Block>
            )}
          </Block>
        </Flex>
        {!!hasScreenshots && matches && productScreenshots()}
      </Container>
      {!isSSR && (
        <React.Suspense fallback={<div />}>
          <LoginRegisterModal
            onSuccess={res => {
              claimProduct(res.user.uid);
            }}
            show={showLoginModal}
            onClose={() => {
              setModal(false);
            }}
          >
            <Text>
              To claim this product page, please login or sign up for an
              account!
            </Text>
          </LoginRegisterModal>
        </React.Suspense>
      )}
    </Block>
  );
};

export default ProductHero;
