import { Block, Container, Text } from '@actovos-consulting-group/ui-core';
import React from 'react';
import RoastOrToastCard from './roast-or-toast-card';

const OurRoastOrToast = ({ product }) => {
  return (
    <Block bg="reallyLightGrey" py={30}>
      <Container>
        <Text
          mb={4}
          mt={4}
          textAlign="center"
          fontWeight="bold"
          fontSize={{ _: 20, xs: 50 }}
        >
          Our Roast or Toast
        </Text>
        {product.roasts.length ? (
          product.roasts.map(roast => (
            <RoastOrToastCard key={roast.id} roast={roast} />
          ))
        ) : (
          <Text textAlign="center" fontSize={20} mb={40}>
            We&lsquo;re working on getting to this product right away!
          </Text>
        )}
      </Container>
    </Block>
  );
};

export default OurRoastOrToast;
