import {
  Block,
  Button,
  Card,
  Flex,
  Text,
} from '@actovos-consulting-group/ui-core';
import { Link } from 'gatsby';
import React from 'react';
import { useToggle } from 'react-use';
import styled, { useTheme } from 'styled-components';
import AuthCondition from '../../../auth-condition';

const CardContainer = styled(Card)`
  background-color: white;
  position: relative;
  color: ${p => p.theme.colors.textColor};
`;

const RightSection = styled(Block)``;

const Overlay = styled(Block)`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 67%
  );
`;

const ReadMoreButton = styled(Button)`
  position: absolute;
  left: 30px;
  bottom: 30px;
  z-index: 10;
`;

const RoastOrToastCard = ({ roast: { Roaster, review, score, title } }) => {
  const theme = useTheme();
  const [isExpanded, toggleExpanded] = useToggle(false);

  return (
    <CardContainer mb={20}>
      {!isExpanded && <Overlay />}
      <Flex pb={65} flexDirection={{ _: 'column-reverse', xs: 'row' }}>
        <Block
          flex={{ _: 'none', xs: 1 }}
          overflow="hidden"
          height={isExpanded ? 'auto' : 250}
        >
          <Text fontSize={{ _: 14, xs: 20 }}>
            Roast or Toaster:{' '}
            <Text
              as={Link}
              to={`/user/${Roaster?.username}`}
              display="inline-block"
              fontWeight="bold"
              color="primary"
            >
              {Roaster?.name}
            </Text>
          </Text>
          <Text mt={3} fontSize={{ _: 20, xs: 30 }} fontWeight="bold">
            {title}
          </Text>
          <Text mt={3} mb={3} dangerouslySetInnerHTML={{ __html: review }} />
          <AuthCondition
            isLoggedOut={() => (
              <ReadMoreButton as={Link} to="/app/register">
                Sign Up or Login to Read More
              </ReadMoreButton>
            )}
            isLoggedIn={() => (
              <ReadMoreButton
                onClick={() => {
                  toggleExpanded();
                }}
              >
                Read {isExpanded ? 'Less' : 'More'}
              </ReadMoreButton>
            )}
          />
        </Block>
        <RightSection
          ml={{ _: 0, xs: 4 }}
          pl={{ _: 0, xs: 4 }}
          borderLeft={{ _: 'none', xs: `1px solid ${theme.colors.mutedGrey}` }}
        >
          <Block
            mb={20}
            p={3}
            bg="primary"
            borderRadius={5}
            width={{ _: 60, xs: 'auto' }}
          >
            <Text
              textAlign="center"
              fontWeight="bold"
              color="white"
              fontSize={{ _: 20, xs: 30 }}
            >
              {score}
            </Text>
          </Block>
        </RightSection>
      </Flex>
    </CardContainer>
  );
};

export default RoastOrToastCard;
