import React from 'react';
import OurRoastOrToast from './components/our-roast-or-toast';
import ProductHero from './components/product-hero';

const Comments = React.lazy(() => import('./components/comments'));

const ProductPage = ({ product }) => {
  const isSSR = typeof window === 'undefined';
  return (
    <>
      <ProductHero product={product} />
      <OurRoastOrToast product={product} />
      {!isSSR && (
        <React.Suspense fallback={<div />}>
          <Comments product={product} />
        </React.Suspense>
      )}
    </>
  );
};

export default ProductPage;
